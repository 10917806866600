import { createFactory } from "@withease/factories";
import type { Random } from "unsplash-js/dist/methods/photos/types";
import { createStore, createEvent, sample, combine, createEffect } from "effector";
import { interval, or, spread } from "patronum";

import { getRandomDolphin } from "../../shared";

export const TestingInternals: unique symbol = Symbol();

export type DolphinApiFactoryParams = {
  delay: number;
  maxDolphins: number;
  autostart?: boolean;
};

const factoryFn = ({ maxDolphins, delay, autostart }: DolphinApiFactoryParams) => {
  const getRandomDolphinFx = createEffect(getRandomDolphin);
  const $historyStore = createStore<Random[]>([]);

  const $maxRequests = createStore(0);
  const $remainingRequests = createStore(0);

  const $currentPhoto = createStore<Random | null>(null);

  const forwardStart = createEvent();
  const forwardStop = createEvent();

  const reverseStart = createEvent();
  const reverseStop = createEvent();

  const start = createEvent();
  const pause = createEvent();
  const reverse = createEvent();
  const reachedEnd = createEvent();

  const { tick: forwardTick, isRunning: $isForwardRunning } = interval({
    timeout: delay,
    start: forwardStart,
    stop: forwardStop,
    leading: true,
  });
  const { tick: reverseTick, isRunning: $isReverseRunning } = interval({
    timeout: delay,
    start: reverseStart,
    stop: reverseStop,
    leading: true,
  });

  sample({ clock: forwardTick, target: getRandomDolphinFx });

  /*
   * When we get API data back, get the data and rate-limit information and distribute to appropriate stores
   */
  sample({
    source: $currentPhoto,
    clock: getRandomDolphinFx.doneData,
    fn(_, data) {
      const payload: Partial<{ $currentPhoto: Random | null; $maxRequests: number; $remainingRequests: number }> = {};
      if (data.response && !Array.isArray(data.response)) {
        payload.$currentPhoto = data.response;
      }
      payload.$maxRequests = parseInt(data.originalResponse.headers.get("x-ratelimit-limit") || "0", 10);
      payload.$remainingRequests = parseInt(data.originalResponse.headers.get("x-ratelimit-remaining") || "0", 10);
      return payload;
    },
    target: spread({ targets: { $currentPhoto, $maxRequests, $remainingRequests } }),
  });

  /*
   * When the API request completes, push the current photo to history, while keeping it to the required minimum
   */
  sample({
    source: combine($currentPhoto, $historyStore),
    clock: getRandomDolphinFx.done,
    filter([currentPhoto]) {
      return !!currentPhoto;
    },
    fn([currentPhoto, historyStore]) {
      return [...historyStore, currentPhoto!].slice(-maxDolphins);
    },
    target: $historyStore,
  });

  /*
   * When running in reverse, get all items and the last, and route them to appropriate stores
   */
  sample({
    source: $historyStore,
    clock: reverseTick,
    filter(store) {
      return store.length > 0;
    },
    fn(store) {
      const xs = store.slice(0, store.length - 1);
      const x = store[store.length - 1];
      return {
        $currentPhoto: x,
        $historyStore: xs,
      };
    },
    target: spread({ targets: { $currentPhoto, $historyStore } }),
  });

  /*
   * When we can no longer go back, stop intervals and trigger reachedEnd Event
   */
  sample({
    source: $historyStore,
    clock: reverseTick,
    filter(store) {
      return store.length === 0;
    },
    target: [pause, reachedEnd],
  });

  sample({ clock: start, target: [reverseStop, forwardStart] });
  sample({ clock: reverse, target: [reverseStart, forwardStop] });
  sample({ clock: pause, target: [forwardStop, reverseStop] });

  const $isRunning = or($isForwardRunning, $isReverseRunning);

  /*
   * This derived store contains a flag whether or not we can progress backwards
   */
  const $canReverse = $historyStore.map((store) => store.length > 0);

  if (autostart) {
    start();
  }

  /*
   * Expose public API of this feature
   */
  return {
    start,
    pause,
    reverse,
    reachedEnd,
    $canReverse,
    $currentPhoto,
    $isRunning,
    $isForwardRunning,
    $isReverseRunning,
    $isLoading: getRandomDolphinFx.pending,
    $maxRequests,
    $remainingRequests,
    [TestingInternals]: {
      $historyStore,
      getRandomDolphinFx,
      forwardTick,
      reverseTick,
    },
  };
};

export const dolphinApiFactory = createFactory(factoryFn);
