import { type FunctionComponent } from "react";
import { Stack, Button } from "@chakra-ui/react";
import { type Event } from "effector";
import { Notice } from "../notice";
export type ControlsProps = {
  api: {
    start: () => void;
    pause: () => void;
    reverse: () => void;
    $isForwardRunning: boolean;
    $isLoading: boolean;
    $isRunning: boolean;
    $canReverse: boolean;
    $maxRequests: number;
    $remainingRequests: number;
  };
  reachedEnd: Event<void>;
};
export const Controls: FunctionComponent<ControlsProps> = ({ api, reachedEnd }) => {
  return (
    <>
      <Stack direction="column">
        <Stack direction="row">
          <Notice message="No more dolphins rememebred" trigger={reachedEnd}>
            <Button onClick={() => api.start()} isDisabled={api.$isForwardRunning} isLoading={api.$isLoading}>
              Start ({api.$remainingRequests}/{api.$maxRequests})
            </Button>
          </Notice>
          <Button onClick={() => api.pause()} isDisabled={!api.$isRunning}>
            Stop
          </Button>
          <Button onClick={() => api.reverse()} isDisabled={!api.$canReverse}>
            Reverse
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
