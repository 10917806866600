import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Event } from "effector";
import { useEffect, type FunctionComponent, type PropsWithChildren } from "react";

export type NoticeProps = {
  trigger: Event<void>;
  message: string;
};

export const Notice: FunctionComponent<PropsWithChildren<NoticeProps>> = ({ children, message, trigger }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    return trigger.watch(() => onOpen());
  }, [trigger, onOpen]);

  return (
    <>
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text>{message}</Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
