import type { FunctionComponent } from "react";
import type { Basic } from "unsplash-js/dist/methods/photos/types";
import { Image, Skeleton, Stack, Heading } from "@chakra-ui/react";

export type UnsplashImageProps = { image: Basic | null };

export const UnsplashImage: FunctionComponent<UnsplashImageProps> = ({ image }) => (
  <Skeleton minH={200} isLoaded={!!image}>
    {image && (
      <>
        <Image
          width={400}
          minH={200}
          alt={image.alt_description || image.description || "Some random dolphin"}
          src={image.urls.small}
        />
        <Stack>{image.description && <Heading>{image.description}</Heading>}</Stack>
      </>
    )}
  </Skeleton>
);
