import "./App.css";

import { DolphinCard } from "../features";

export function App() {
  return (
    <div className="App">
      <header className="App-header">
        <DolphinCard />
      </header>
    </div>
  );
}
