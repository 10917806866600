import { Card, CardBody, CardFooter, Divider } from "@chakra-ui/react";

import { invoke } from "@withease/factories";
import { useUnit } from "effector-react";
import { FunctionComponent } from "react";
import { UnsplashImage, Controls } from "../../../widgets";
import { dolphinApiFactory } from "../dolphinApi";

const dolphinApi = invoke(dolphinApiFactory, { delay: 2000, maxDolphins: 5 });

export type DolphinCardProps = {};

export const DolphinCard: FunctionComponent<DolphinCardProps> = () => {
  const api = useUnit(dolphinApi);
  return (
    <>
      <Card maxW="sm" data-testid="dolphin-card">
        <CardBody>
          <UnsplashImage image={api.$currentPhoto} />
        </CardBody>
        <Divider />
        <CardFooter>
          <Controls api={api} reachedEnd={dolphinApi.reachedEnd} />
        </CardFooter>
      </Card>
    </>
  );
};
